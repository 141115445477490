import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import moment from 'moment';
import helpers from '../../mixins/helpers';
import departamento from './departamento';

const state = {
    id: '',
    departamentoId: 0,
    departamento: null,
    departamentoNombre: null,
    nombre: '',
    inicial: false,
    inactivo: false,
    rowVersion: null
};

const mutations = {
    updateField,

    'INICIALIZAR' (state, procesoId) {
        state.id = procesoId;
        state.departamentoId = 0;
        state.departamento = null;
        state.departamentoNombre = null;
        state.nombre = '';
        state.inicial = false;
        state.inactivo = false;
        state.rowVersion = null;
    },
    'SET_PROCESO'(state, proceso) {
        state.departamentoId = proceso.departamento? proceso.departamento.id : 0;
        state.departamento = proceso.departamento;
        state.departamentoNombre = proceso.departamento? proceso.departamento.nombre.trim() : null;
        state.nombre = proceso.nombre.trim();
        state.inicial = (/true/i).test(proceso.inicial);
        state.inactivo = (/true/i).test(proceso.inactivo);
        state.rowVersion = proceso.rowVersion;
    },
    'SET_DEPARTAMENTO'(state, departamento) {
        state.departamento = departamento;
        
        if (departamento) {
            state.departamentoId = departamento.id? departamento.id : 0;
            state.departamentoNombre = departamento.nombre? departamento.nombre.trim() : null;
        } else {
            state.departamentoId = 0;
            state.departamentoNombre = null;
        }
    },
};

const getters = {
    getField,
};

const actions = {
    inicializar ( { commit }, procesoId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR', procesoId);
            
            if (procesoId != '') {
                apiAxios.get(`procesos/${procesoId}/abm`)
                    .then(res => {
                        commit('SET_PROCESO', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();
            }
        })            
    },
    setDepartamento ( { commit }, departamento ) {
        return new Promise((resolve, reject) => {
            commit('SET_DEPARTAMENTO', departamento);
            resolve();
        })
    },
    save ( { state } ) {
        return new Promise((resolve, reject) => {
            let save = true;
            let errorMessage = '';
            
            if (save) {
                let proceso= {
                    departamentoId: state.departamentoId,
                    nombre: state.nombre.toUpperCase().trim(),
                    inicial: (/true/i).test(state.inicial),
                    inactivo: (/true/i).test(state.inactivo)
                };

                let url = 'procesos';
                if (state.id != '') {
                    url += '/' + state.id;
                    proceso.rowVersion = state.rowVersion;
                }

                apiAxios.post(url, proceso)
                    .then(() => resolve())
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                reject(errorMessage);
            }
        })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}