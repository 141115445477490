import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    /* Registro de usuarios */
    usuarios: [],
    /* Paginacion */
    paginaActual: 1,
    registrosPorPagina: 10,
    totalRegistros: 0,
    totalPaginas: 0,
    /* Filtro */
    nombre: '',
    incluirInactivos: false,

    list: [],
    selectList: [],
};

const mutations = {
    updateField,

    'INICIALIZAR'(state) {
        state.usuarios = [];
    },
    'SET_USUARIOS'(state, usuarios) {
        state.usuarios = usuarios;
    },
    'PAGINATION'(state, params) {
        state.paginaActual = params.paginaActual;
        state.registrosPorPagina = params.registrosPorPagina;
        state.totalRegistros = params.totalRegistros;
        state.totalPaginas = params.totalPaginas;
    },

    'INICIALIZAR_LIST'(state) {
        state.list = [];
        state.selectList = [];
    },
    'SET_LIST'(state, usuarios) {
        state.list = usuarios;
        state.selectList = usuarios.map(usuario => usuario.nombreCompleto.trim());
    },
};

const actions = {
    getUsuarios: ({commit}) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR');

            var queryString = '?pageSize=' + state.registrosPorPagina
                + '&pageNumber=' + state.paginaActual;
            
            if (state.nombre != '') {
                queryString += '&nombre=' + state.nombre;
            }
            if ((/true/i).test(state.incluirInactivos)) {
                queryString += '&incluirInactivos=true';
            }

            apiAxios.get('usuarios' + queryString)
                .then(res => {
                    if (res.headers.pagination){
                        const pagination= JSON.parse(res.headers.pagination)
                        var params = {
                            paginaActual: pagination.currentPage,
                            registrosPorPagina: pagination.itemsPerPage,
                            totalRegistros: pagination.totalItems,
                            totalPaginas: pagination.totalPages,
                        }    
                        commit('PAGINATION', params);
                    }
                    commit('SET_USUARIOS', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    getList: ({commit}) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_LIST');

            apiAxios.get('usuarios/list')
                .then(res => {
                    commit('SET_LIST', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
};

const getters = {
    getField,

    list(state) {
        return state.list;
    },
    selectList(state) {
        return state.selectList;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}