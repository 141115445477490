import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';

const state = {
    programaDatos: [],
};

const mutations = {
    updateField,

    'INICIALIZAR_DATOS'(state) {
        state.programaDatos = [];
    },
    'SET_DATOS'(state, programaDatos) {
        state.programaDatos = programaDatos;
    },
};

const actions = {
    getDatos ( { commit }, programaId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_DATOS');

            if (programaId) {
                apiAxios.get('programas/' + programaId ) 
                    .then(res => {
                        let programaDatos = [
                            { 'nombre': 'nombre', 'campo': 'Nombre', 'valor': res.data.nombre, 'formato': '' },
                            { 'nombre': 'descripcion', 'campo': 'Descripción (Web)', 'valor': res.data.descripcion, 'formato': '' },
                            { 'nombre': 'inactivo', 'campo': 'Inactivo', 'valor': res.data.inactivo? 'Si' : 'No', 'formato': '' },
                        ];

                        commit('SET_DATOS', programaDatos);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            }
        })            
    },
};

const getters = {
    getField
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}