import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import moment from 'moment';
import helpers from '../../mixins/helpers';

const state = {
    id: '',
    nombre: '',
    inicial: false,
    final: false,
    inactivo: false,
    rowVersion: null
};

const mutations = {
    updateField,

    'INICIALIZAR' (state, solicitudEstadoId) {
        state.id = solicitudEstadoId;
        state.nombre = '';
        state.inactivo = false;
        state.inicial = false;
        state.final = false;
        state.rowVersion = null;
    },
    'SET_SOLICITUD_ESTADO'(state, solicitudEstado) {
        state.nombre = solicitudEstado.nombre.trim();
        state.inactivo = (/true/i).test(solicitudEstado.inactivo);
        state.inicial = (/true/i).test(solicitudEstado.inicial);
        state.final = (/true/i).test(solicitudEstado.final);
        state.rowVersion = solicitudEstado.rowVersion;
    },
};

const getters = {
    getField,
};

const actions = {
    inicializar ( { commit }, solicitudEstadoId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR', solicitudEstadoId);
            
            if (solicitudEstadoId != '') {
                apiAxios.get('solicitudes-estados/' + solicitudEstadoId) 
                    .then(res => {
                        commit('SET_SOLICITUD_ESTADO', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();
            }
        })            
    },
    save ( { state } ) {
        return new Promise((resolve, reject) => {
            let save = true;
            let errorMessage = '';
            
            if (save) {
                let estado= {
                    nombre: state.nombre.toUpperCase().trim(),
                    inicial: (/true/i).test(state.inicial),
                    final: (/true/i).test(state.final),
                    inactivo: (/true/i).test(state.inactivo)
                };

                let url = 'solicitudes-estados';
                if (state.id != '') {
                    url += '/' + state.id;
                    estado.rowVersion = state.rowVersion;
                }

                apiAxios.post(url, estado)
                    .then(() => resolve())
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                reject(errorMessage);
            }
        })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}