import Vue from 'vue';
import Vuex from 'vuex';

import departamento from './modules/departamento';
import departamentoDatos from './modules/departamentoDatos';
import departamentos from './modules/departamentos';
import formulario from './modules/formulario';
import formularioDatos from './modules/formularioDatos';
import formularios from './modules/formularios';
import formularioCampo from './modules/formularioCampo';
import formulariosCamposTipos from './modules/formulariosCamposTipos';
import formularioLista from './modules/formularioLista';
import formularioListaDatos from './modules/formularioListaDatos';
import formulariosListas from './modules/formulariosListas';
import proceso from './modules/proceso';
import procesoDatos from './modules/procesoDatos';
import procesos from './modules/procesos';
import programa from './modules/programa';
import programaDatos from './modules/programaDatos';
import programas from './modules/programas';
import solicitud from './modules/solicitud';
import solicitudes from './modules/solicitudes';
import solicitudDatos from './modules/solicitudDatos';
import solicitudEstado from './modules/solicitudEstado';
import solicitudEstadoDatos from './modules/solicitudEstadoDatos';
import solicitudesEstados from './modules/solicitudesEstados';
import tiposDocumentos from './modules/tiposDocumentos';

import empresa from './modules/empresa';
import spinnerProcesando from './modules/spinner-procesando';
import user from './modules/user';
import usuario from './modules/usuario';
import usuarios from './modules/usuarios';
import usuariosRoles from './modules/usuariosRoles';
import usuarioPerfil from './modules/usuarioPerfil';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        departamento,
        departamentoDatos,
        departamentos,
        formulario,
        formularioDatos,
        formularios,
        formularioCampo,
        formulariosCamposTipos,
        formularioLista,
        formularioListaDatos,
        formulariosListas,
        proceso,
        procesoDatos,
        procesos,
        programa,
        programaDatos,
        programas,
        solicitud,
        solicitudes,
        solicitudDatos,
        solicitudEstado,
        solicitudesEstados,
        solicitudEstadoDatos,
        tiposDocumentos,

        empresa,
        spinnerProcesando,
        user,
        usuario,
        usuarios,
        usuariosRoles,
        usuarioPerfil
    }
});