import { getField, updateField } from 'vuex-map-fields';
import moment from 'moment';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    id: 0,
    username: '',
    apellido: '',
    nombre: '',
    email: '',
    usuarioRolId: 0,
    usuarioRol: null,
    usuarioRolNombre: null,
    departamentoId: 0,
    departamento: null,
    departamentoNombre: null,
    rowVersion: null,
    
    usuarioDatos: [],

    // usuarioPassTemporal: {
    //     username: '',
    //     password: ''
    // }
};

const mutations = {
    updateField,

    'INICIALIZAR_USUARIO'(state, usuarioId) {
        state.id = usuarioId;
        state.username = '';
        state.apellido = '';
        state.nombre = '';
        state.email = '';
        state.usuarioRolId = 0;
        state.usuarioRol = null;
        state.usuarioRolNombre = null;
        state.departamentoId = 0;
        state.departamento = null;
        state.departamentoNombre = null;
        state.rowVersion = null;
    },
    'SET_USUARIO'(state, usuario) {
        state.username = usuario.username;
        state.apellido = usuario.apellido;
        state.nombre = usuario.nombre;
        state.email = usuario.email;
        state.usuarioRolId = usuario.usuarioRolId;
        state.usuarioRol = usuario.usuarioRol;
        state.usuarioRolNombre = usuario.usuarioRol? usuario.usuarioRol.nombre.trim() : null;
        state.departamentoId = usuario.departamentoId;
        state.departamento = usuario.departamento;
        state.departamentoNombre = usuario.departamento? usuario.departamento.nombre.trim() : null;
        state.rowVersion = usuario.rowVersion;
    },
    'SET_USUARIO_ROL'(state, usuarioRol) {
        state.usuarioRol = usuarioRol;
        if (usuarioRol) {
            state.usuarioRolId = usuarioRol.id? usuarioRol.id : 0;
            state.usuarioRolNombre = usuarioRol.nombre? usuarioRol.nombre.trim() : null;
        } else {
            state.usuarioRolId = 0;
            state.usuarioRolNombre = null;
        }
    },
    'SET_DEPARTAMENTO'(state, departamento) {
        state.departamento = departamento;
        if (departamento) {
            state.departamentoId = departamento.id? departamento.id : 0;
            state.departamentoNombre = departamento.nombre? departamento.nombre.trim() : null;
        } else {
            state.departamentoId = 0;
            state.departamentoNombre = null;
        }
    },

    // 'INICIALIZAR_USUARIO_PASS_TEMPORAL' (state) {
    //     state.usuarioPassTemporal.username = '';
    //     state.usuarioPassTemporal.password = '';
    // },
    // 'SET_USUARIO_PASS_TEMPORAL' (state, usuarioPassTemporal) {
    //     if (usuarioPassTemporal) {
    //         state.usuarioPassTemporal.username = usuarioPassTemporal.username? usuarioPassTemporal.username.trim() : '';
    //         state.usuarioPassTemporal.password = usuarioPassTemporal.password? usuarioPassTemporal.password.trim() : '';
    //     } else {
    //         state.usuarioPassTemporal.username = '';
    //         state.usuarioPassTemporal.password = '';
    //     }
    // },

    'INICIALIZAR_DATOS' (state) {
        state.usuarioDatos = [];
    },
    'SET_USUARIO_DATOS' (state, datos) {
        state.usuarioDatos = datos;
    }
};

const getters = {
    getField,
};

const actions = {
    inicializar ( { commit }, usuarioId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_USUARIO', usuarioId);

            if (usuarioId != 0) {
                apiAxios.get(`usuarios/${state.id}/abm`) 
                    .then(res => {
                        commit('SET_USUARIO', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();
            }
        })
    },    
    save ( { state } ) {
        return new Promise((resolve, reject) => {
            let usuario= {
                apellido: state.apellido.trim(),
                nombre: state.nombre.trim(),
                email: state.email.trim(),
                usuarioRolId: state.usuarioRolId,
                departamentoId: state.departamentoId,
                rowVersion: state.rowVersion
            }

            apiAxios.post(`usuarios/${state.id}`, usuario)
                .then(() => resolve())
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    setUsuarioRol: ({commit}, usuarioRol) => {
        commit('SET_USUARIO_ROL', usuarioRol);
    },
    setDepartamento: ({commit}, departamento) => {
        commit('SET_DEPARTAMENTO', departamento);
    },
    getDatos ( { commit }, usuarioId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_DATOS');

            if (usuarioId != 0) {
                apiAxios.get('usuarios/' + usuarioId ) 
                .then(res => {
                    let datos = [
                        { 'nombre': 'username', 'campo': 'Username', 'valor': res.data.username, 'formato': '' },
                        { 'nombre': 'nombreCompleto', 'campo': 'Nombre', 'valor': res.data.nombreCompleto, 'formato': '' },
                        { 'nombre': 'email', 'campo': 'Email', 'valor': res.data.email, 'formato': '' },
                        { 'nombre': 'usuarioRol', 'campo': 'Rol', 'valor': res.data.usuarioRol.trim(), 'formato': '' },
                        { 'nombre': 'departamento', 'campo': 'Departamento', 'valor': res.data.departamento.trim(), 'formato': '' },
                        { 'nombre': 'fechaConfirmacion', 'campo': 'Confirmación', 'valor': res.data.fechaConfirmacion, 'formato': 'fechaHora' },
                    ];
                    
                    if (moment(res.data.fechaConfirmacion).format('YYYY-MM-DD') != '1900-01-01') { 
                        datos.push({ 'nombre': 'fechaUltimoLogueo', 'campo': 'Últ. Logueo', 'valor': res.data.fechaUltimoLogueo, 'formato': 'fechaHora' });
                    }

                    if (moment(res.data.fechaBlackList).format('YYYY-MM-DD') != '1900-01-01') { 
                        datos.push({ 'nombre': 'fechaBlackList', 'campo': 'Bloqueada Hasta', 'valor': res.data.fechaBlackList, 'formato': 'fechaHora' })
                    }

                    commit('SET_USUARIO_DATOS', datos);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();
            }
        })
    },

    activar ( {}, usuarioId ) {
        return new Promise((resolve, reject) => {
            apiAxios.post(`usuarios/${usuarioId}/activar`)
                .then(() => resolve())
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    inactivar ( {}, usuarioId ) {
        return new Promise((resolve, reject) => {
            apiAxios.post(`usuarios/${usuarioId}/inactivar`)
                .then(() => resolve())
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    resetPassword ( {}, params ) {
        return new Promise((resolve, reject) => {
            apiAxios.post('usuarios/reset-password', params)
                .then(() => resolve())
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    sendInvite ( {}, usuarioId ) {
        return new Promise((resolve, reject) => { 
          apiAxios.post(`usuarios/${usuarioId}/send-invite`)
            .then(() => resolve())
            .catch(error => reject(helpers.methods.getErrorMessage(error)));
        }, error => {
          reject(error);
        })
    },    
    // resetPasswordUsuario ( { commit }, usuarioId ) {
    //     return new Promise((resolve, reject) => {
    //         commit('INICIALIZAR_USUARIO_PASS_TEMPORAL');

    //         apiAxios.post('usuarios/' + usuarioId + '/reset-password')
    //             .then(res => {
    //                 commit('SET_USUARIO_PASS_TEMPORAL', res.data);
    //                 resolve();
    //             })
    //             .catch(error => reject(helpers.methods.getErrorMessage(error)));
    //     })
    // },
    registrar ( {}, params ) {
        return new Promise((resolve, reject) => {
            // commit('INICIALIZAR_USUARIO_PASS_TEMPORAL'); 
            apiAxios.post('usuarios/registrar', params)
                .then(() => resolve())
                // .then(res => {
                //     commit('SET_USUARIO_PASS_TEMPORAL', res.data);
                //     resolve();
                // })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}