import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import moment from 'moment';
import helpers from '../../mixins/helpers';

const state = {
    id: '',
    programaId: '',
    programa: null,
    programaNombre: null,
    nombre: '',
    personaFisica: false,
    personaJuridica: false,
    inactivo: false,
    rowVersion: null,

    campos: [],
    condiciones: []
};

const mutations = {
    updateField,

    'INICIALIZAR' (state, formularioId) {
        state.id = formularioId;
        state.programaId = '';
        state.programa = null;
        state.programaNombre = null;
        state.nombre = '';
        state.personaFisica = false;
        state.personaJuridica = false;
        state.inactivo = false;
        state.rowVersion = null;

        state.campos = [];
        state.condiciones = [];
    },
    'SET_FORMULARIO'(state, formulario) {
        state.programaId = formulario.programa? formulario.programa.id : '';
        state.programa = formulario.programa;
        state.programaNombre = formulario.programa? formulario.programa.nombre.trim() : null;
        state.nombre = formulario.nombre.trim();
        state.personaFisica = (/true/i).test(formulario.personaFisica);
        state.personaJuridica = (/true/i).test(formulario.personaJuridica);
        state.inactivo = (/true/i).test(formulario.inactivo);
        state.rowVersion = formulario.rowVersion;

        state.campos = formulario.campos.map(campo => ({
            id: helpers.methods.getUUID(),
            campoId: campo.id,
            nombre: campo.nombre.trim(),
            descripcion: campo.descripcion.trim(),
            formularioCampoTipoId: campo.formularioCampoTipo? campo.formularioCampoTipo.id : 0,
            formularioCampoTipo: campo.formularioCampoTipo,
            formularioCampoTipoNombre: campo.formularioCampoTipo? campo.formularioCampoTipo.nombre.trim() : null,
            formularioListaId: campo.formularioLista? campo.formularioLista.id : '',
            formularioLista: campo.formularioLista,
            formularioListaNombre: campo.formularioLista? campo.formularioLista.nombre.trim() : null,
            longitudMaxima: parseInt(campo.longitudMaxima),
            orden: parseInt(campo.orden),
            requerido: (/true/i).test(campo.requerido)
        }));

        state.condiciones = formulario.condiciones;
    },
    'SET_PROGRAMA'(state, programa) {
        state.programa = programa;
        if (programa) {
            state.programaId = programa.id? programa.id : 0;
            state.programaNombre = programa.nombre? programa.nombre.trim() : null;
        } else {
            state.programaId = 0;
            state.programaNombre = null;
        }
    },
    'ADD_CAMPO' (state, campo) {
        let orden = state.campos.length + 1;

        state.campos.push({
            id: helpers.methods.getUUID(),
            campoId: 0,
            nombre: campo.nombre.trim(),
            descripcion: campo.descripcion.trim(),
            formularioCampoTipoId: campo.formularioCampoTipo? campo.formularioCampoTipo.id : 0,
            formularioCampoTipo: campo.formularioCampoTipo,
            formularioCampoTipoNombre: campo.formularioCampoTipo? campo.formularioCampoTipo.nombre.trim() : null,
            formularioListaId: campo.formularioLista? campo.formularioLista.id : '',
            formularioLista: campo.formularioLista,
            formularioListaNombre: campo.formularioLista? campo.formularioLista.nombre.trim() : null,
            longitudMaxima: parseInt(campo.longitudMaxima),
            // orden: parseInt(campo.orden),
            orden,
            requerido: (/true/i).test(campo.requerido)
        })
    },
    'UPDATE_CAMPO' (state, campo) {
        let formularioCampo = state.campos.find(t => t.id == campo.id);

        if (formularioCampo) {
            formularioCampo.nombre = campo.nombre.trim();
            formularioCampo.descripcion = campo.descripcion.trim();
            formularioCampo.formularioCampoTipoId = campo.formularioCampoTipo? campo.formularioCampoTipo.id : 0;
            formularioCampo.formularioCampoTipo = campo.formularioCampoTipo;
            formularioCampo.formularioCampoTipoNombre = campo.formularioCampoTipo? campo.formularioCampoTipo.nombre.trim() : null;
            formularioCampo.formularioListaId = campo.formularioLista? campo.formularioLista.id : '';
            formularioCampo.formularioLista = campo.formularioLista;
            formularioCampo.formularioListaNombre = campo.formularioLista? campo.formularioLista.nombre.trim() : null;
            formularioCampo.longitudMaxima = parseInt(campo.longitudMaxima);
            // formularioCampo.orden = parseInt(campo.orden);
            formularioCampo.requerido = (/true/i).test(campo.requerido);
        }
    },
    'REMOVE_CAMPO' (state, campoId) {
        let itemKey = state.campos.findIndex(t => t.id == campoId);
        
        if (itemKey != -1) {
            state.campos.splice(itemKey, 1);

            /* Renumeramos el orden de los campos */
            let orden = 0;
            state.campos
                .sort((a, b) => a.orden - b.orden)
                .forEach(campo => {
                    orden++;
                    campo.orden = orden;
                });
        }
    }
};

const getters = {
    getField,
};

const actions = {
    inicializar ( { commit }, formularioId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR', formularioId);
            
            if (formularioId != '') {
                apiAxios.get(`formularios/${formularioId}/abm`) 
                    .then(res => {
                        commit('SET_FORMULARIO', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();
            }
        })
    },
    setPrograma ( { commit }, programa ) {
        return new Promise((resolve, reject) => {
            commit('SET_PROGRAMA', programa);
            resolve();
        })
    },
    addCampo ( { commit }, campo ) {
        return new Promise((resolve, reject) => {
            commit('ADD_CAMPO', campo);
            resolve();
        })
    },
    updateCampo ( { commit }, campo ) {
        return new Promise((resolve, reject) => {
            commit('UPDATE_CAMPO', campo);
            resolve();
        })
    },
    removeCampo ( { commit }, campoId ) {
        return new Promise((resolve, reject) => {
            commit('REMOVE_CAMPO', campoId);
            resolve();
        })
    },
    save ( { state } ) {
        return new Promise((resolve, reject) => {
            let save = true;
            let errorMessage = '';
            
            if (save) {
                let campos = [];
                state.campos.forEach(campo => {
                    let campoToAdd = {
                        id: campo.campoId,
                        formularioCampoTipoId: parseInt(campo.formularioCampoTipoId),
                        // formularioListaId: campo.formularioListaId,
                        nombre: campo.nombre.trim(),
                        descripcion: campo.descripcion.trim(),
                        longitudMaxima: parseInt(campo.longitudMaxima),
                        orden: parseInt(campo.orden),
                        requerido: (/true/i).test(campo.requerido)
                    }

                    if (campo.formularioListaId != '') {
                        campoToAdd.formularioListaId = campo.formularioListaId
                    }

                    campos.push(campoToAdd);
                });

                let formulario= {
                    programaId: state.programaId,
                    nombre: state.nombre.toUpperCase().trim(),
                    personaFisica: (/true/i).test(state.personaFisica),
                    personaJuridica: (/true/i).test(state.personaJuridica),
                    inactivo: (/true/i).test(state.inactivo),
                    campos,
                    condiciones: state.condiciones
                };

                let url = 'formularios';
                if (state.id != '') {
                    url += '/' + state.id;
                    formulario.rowVersion = state.rowVersion;
                }

                apiAxios.post(url, formulario)
                    .then(() => resolve())
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                reject(errorMessage);
            }
        })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}