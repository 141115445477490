import { getField, updateField } from 'vuex-map-fields';
import moment from 'moment';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    programas: [],
    /* Paginacion */
    paginaActual: 1,
    registrosPorPagina: 10,
    totalRegistros: 0,
    totalPaginas: 0,
    // filtroNumero: false,
    // numero: 0,
    // filtroOpciones: true,
    // proveedor: null,
    // fechaDesde: moment(new Date()).add(-30, 'days').format('YYYY-MM-DD'),
    // fechaHasta: moment(new Date()).format('YYYY-MM-DD'),
    // usuario: null,
    nombre: '',
    incluirBorrados: false,
    sortByDescending: true,

    list: [],
    selectList: [],
};

const mutations = {
    updateField,

    'INICIALIZAR'(state) {
        state.programas = [];
    },
    'SET_PROGRAMAS'(state, programas) {
        state.programas = programas;
    },
    'SET_PAGINATION'(state, params) {
        state.paginaActual = params.paginaActual;
        state.registrosPorPagina = params.registrosPorPagina;
        state.totalRegistros = params.totalRegistros;
        state.totalPaginas = params.totalPaginas;
    },

    'INICIALIZAR_LIST'(state) {
        state.list = [];
        state.selectList = [];
    },
    'SET_LIST'(state, programas) {
        state.list = programas;
        state.selectList = programas.map(programa => programa.nombre.trim());
    },
};

const actions = {
    getProgramas: ({ commit }) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR');

            let queryString = '?pageSize=' + state.registrosPorPagina
                + '&pageNumber=' + state.paginaActual;

            if (state.nombre != '') {
                queryString += '&nombre=' + state.nombre;
            }

            if ((/true/i).test(state.incluirInactivos)) {
                queryString += '&incluirInactivos=true';
            }

            apiAxios.get('programas' + queryString)
                .then(res => {
                    if (res.headers.pagination){
                        const pagination= JSON.parse(res.headers.pagination)
                        var params = {
                            paginaActual: pagination.currentPage,
                            registrosPorPagina: pagination.itemsPerPage,
                            totalRegistros: pagination.totalItems,
                            totalPaginas: pagination.totalPages,
                        }    
                        commit('SET_PAGINATION', params);
                    }
                    commit('SET_PROGRAMAS', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        }, error => {
            reject(error);
        })
    },
    delete: ( {}, programaId ) => {
        return new Promise((resolve, reject) => {
            apiAxios.post( `programas/${programaId}/delete`)
                .then(() => resolve())
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    getList: ({ commit }, params) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_LIST');

            let url = 'programas/list';

            if (params) {
                if (params.incluirFormularios) {
                    url += '/activos';
                }
            }

            apiAxios.get(url)
                .then(res => {
                    commit('SET_LIST', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        }, error => {
            reject(error);
        })
    },
};

const getters = {
    getField,

    list (state) {
        return state.list;
    },
    selectList (state) {
        return state.selectList;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}