import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import moment from 'moment';
import helpers from '../../mixins/helpers';

const state = {
    id: '',
    nombre: '',
    inicial: false,
    inactivo: false,
    rowVersion: null
};

const mutations = {
    updateField,

    'INICIALIZAR' (state, departamentoId) {
        state.id = departamentoId;
        state.nombre = '';
        state.inicial = false;
        state.inactivo = false;
        state.rowVersion = null;
    },
    'SET_DEPARTAMENTO'(state, departamento) {
        state.nombre = departamento.nombre.trim();
        state.inicial = (/true/i).test(departamento.inicial);
        state.inactivo = (/true/i).test(departamento.inactivo);
        state.rowVersion = departamento.rowVersion;
    },
};

const getters = {
    getField,
};

const actions = {
    inicializar ( { commit }, departamentoId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR', departamentoId);
            
            if (departamentoId != '') {
                apiAxios.get(`departamentos/${departamentoId}/abm`)
                    .then(res => {
                        commit('SET_DEPARTAMENTO', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();
            }
        })            
    },
    save ( { state } ) {
        return new Promise((resolve, reject) => {
            let save = true;
            let errorMessage = '';
            
            if (save) {
                let departamento= {
                    nombre: state.nombre.toUpperCase().trim(),
                    inicial: (/true/i).test(state.inicial),
                    inactivo: (/true/i).test(state.inactivo)
                };

                let url = 'departamentos';
                if (state.id != '') {
                    url += '/' + state.id;
                    departamento.rowVersion = state.rowVersion;
                }

                apiAxios.post(url, departamento)
                    .then(() => resolve())
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                reject(errorMessage);
            }
        })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}