import { getField, updateField } from 'vuex-map-fields';
import helpers from '../../mixins/helpers';

const state = {
    id: '',
    nombre: '',
    descripcion: '',
    formularioCampoTipoId: 0,
    formularioCampoTipo: null,
    formularioCampoTipoNombre: null,
    formularioListaId: 0,
    formularioLista: null,
    formularioListaNombre: null,
    longitudMaxima: 0,
    orden: 0,
    requerido: false,

    /* Propiedades del tipo de campo */
    requiereLongitud: false,
    requiereLista: false
};

const mutations = {
    updateField,

    'INICIALIZAR' (state) {
        state.id = '';
        state.nombre = '';
        state.descripcion = '';
        state.formularioCampoTipoId = 0;
        state.formularioCampoTipo = null;
        state.formularioCampoTipoNombre = null;
        state.formularioListaId = 0;
        state.formularioLista = null;
        state.formularioListaNombre = null;
        state.longitudMaxima = 0;
        state.orden = 0;
        state.requerido = false;

        state.requiereLongitud = false;
        state.requiereLista = false;
    },
    'SET_CAMPO'(state, campo) {
        state.id = campo.id;
        state.nombre = campo.nombre;
        state.descripcion = campo.descripcion;
        state.formularioCampoTipoId = campo.formularioCampoTipo? campo.formularioCampoTipo.id : 0;
        state.formularioCampoTipo = campo.formularioCampoTipo;
        state.formularioCampoTipoNombre = campo.formularioCampoTipo? campo.formularioCampoTipo.nombre.trim() : null;
        state.formularioListaId = campo.formularioLista? campo.formularioLista.id : 0;
        state.formularioLista = campo.formularioLista;
        state.formularioListaNombre = campo.formularioLista? campo.formularioLista.nombre.trim() : null;
        state.longitudMaxima = campo.longitudMaxima;
        state.orden = campo.orden;
        state.requerido = (/true/i).test(campo.requerido);
        
        state.requiereLongitud = (/true/i).test(campo.formularioCampoTipo? campo.formularioCampoTipo.requiereLongitud : false);
        state.requiereLista = (/true/i).test(campo.formularioCampoTipo? campo.formularioCampoTipo.requiereLista : false);
    },
    'SET_FORMULARIO_CAMPO_TIPO'(state, formularioCampoTipo) {
        state.formularioCampoTipo = formularioCampoTipo;
        if (formularioCampoTipo) {
            state.formularioCampoTipoId = formularioCampoTipo.id? formularioCampoTipo.id : 0;
            state.formularioCampoTipoNombre = formularioCampoTipo.nombre? formularioCampoTipo.nombre.trim() : null;
            
            state.requiereLongitud = (/true/i).test(formularioCampoTipo.requiereLongitud);
            state.requiereLista = (/true/i).test(formularioCampoTipo.requiereLista);

            /* Reinicializamos los parametros de configuración */
            if (!state.requiereLongitud) {
                state.longitudMaxima = 0;
            }

            if (!state.requiereLista) {
                state.formularioListaId = 0;
                state.formularioLista = null;
                state.formularioListaNombre = null;
            }
        } else {
            state.formularioCampoTipoId = 0;
            state.formularioCampoTipoNombre = null;
            
            state.requiereLongitud = false;
            state.requiereLista = false;
            state.formularioListaId = 0;
            state.formularioLista = null;
            state.formularioListaNombre = null;
            state.longitudMaxima = 0;
        }
    },
    'SET_FORMULARIO_LISTA'(state, formularioLista) {
        state.formularioLista = formularioLista;
        if (formularioLista) {
            state.formularioListaId = formularioLista.id? formularioLista.id : 0;
            state.formularioListaNombre = formularioLista.nombre? formularioLista.nombre.trim() : null;
        } else {
            state.formularioListaId = '';
            state.formularioListaNombre = null;
        }
    },    
};

const getters = {
    getField,

    formularioCampo (state) {
        return {
            id: state.id,
            nombre: state.nombre.trim(),
            descripcion: state.descripcion.trim(),
            formularioCampoTipo: state.formularioCampoTipo,
            formularioLista: state.formularioLista,
            longitudMaxima: parseInt(state.longitudMaxima),
            orden: parseInt(state.orden),
            requerido: (/true/i).test(state.requerido)
        }
    }
};

const actions = {
    inicializar ( { commit }, campo ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR');

            if (campo) {
                commit('SET_CAMPO', campo);
                resolve();
            } else {
                resolve();
            }
        })
    },
    setFormularioCampoTipo ( { commit }, formularioCampoTipo ) {
        return new Promise((resolve, reject) => {
            commit('SET_FORMULARIO_CAMPO_TIPO', formularioCampoTipo);
            resolve();
        })
    },
    setFormularioLista ( { commit }, formularioLista ) {
        return new Promise((resolve, reject) => {
            commit('SET_FORMULARIO_LISTA', formularioLista);
            resolve();
        })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}