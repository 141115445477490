import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';

const state = {
    departamentoDatos: [],
};

const mutations = {
    updateField,

    'INICIALIZAR_DATOS'(state) {
        state.departamentoDatos = [];
    },
    'SET_DATOS'(state, departamentoDatos) {
        state.departamentoDatos = departamentoDatos;
    },
};

const actions = {
    getDatos ( { commit }, departamentoId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_DATOS');

            if (departamentoId) {
                apiAxios.get('departamentos/' + departamentoId ) 
                    .then(res => {
                        let departamentoDatos = [
                            { 'nombre': 'nombre', 'campo': 'Nombre', 'valor': res.data.nombre, 'formato': '' },
                            { 'nombre': 'inicial', 'campo': 'Inicial', 'valor': res.data.inicial? 'Si' : 'No', 'formato': '' },
                            { 'nombre': 'inactivo', 'campo': 'Inactivo', 'valor': res.data.inactivo? 'Si' : 'No', 'formato': '' },
                        ];

                        commit('SET_DATOS', departamentoDatos);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            }
        })            
    },
};

const getters = {
    getField
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}